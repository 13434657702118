@import 'toolkit';

html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: color(identityBlack);
  background: color(background);

  &.zef-dark-theme {
    color: color(textWhite);
    background: color(backgroundDark);

    body {
      background: color(backgroundDark);
    }
  }
}

body {
  min-height: 100%;
  font-family: $fontMain;
  overflow: hidden;
  background: color(background);
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }

  outline: none;
}

::selection {

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.zwe-grid {
  display: grid;
}

.zwe-grid--1fr-1fr {
  grid-template-columns: 1fr 1fr;
}

.c-trlog-timerange-pop {

}

.c-zerops-tooltip {
  background: #fff;
  box-shadow: rgba(90, 90, 90, 0.211) 0px 10px 130px !important;
  font-size: 12px;
  line-height: 16px;
  padding: 12px !important;
  color: #303030 !important;
  font-weight: 500;
}
