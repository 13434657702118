@mixin generate-sizes-utilities($prefix: 'fx') {
  $widths: (
    'w-25': 25%,
    'w-50': 50%,
    'w-75': 75%,
    'w-100': 100%,
    'w-auto': auto,
    'w-screen': 100vw
  );

  $heights: (
    'h-25': 25%,
    'h-50': 50%,
    'h-75': 75%,
    'h-100': 100%,
    'h-auto': auto,
    'h-screen': 100vh
  );


  // Pixel-based sizes with design-friendly numbers
  $pixel-sizes: (
    // Small sizes
    '4px': 4px,
    '8px': 8px,
    '12px': 12px,
    '16px': 16px,
    '24px': 24px,
    '32px': 32px,
    '40px': 40px,
    '48px': 48px,  // Common for padding/margin
    '64px': 64px,

    // Medium sizes
    '72px': 72px,
    '96px': 96px,
    '128px': 128px,
    '160px': 160px,
    '192px': 192px,
    '220px': 220px,
    '256px': 256px,
    '320px': 320px,

    // Larger design sizes
    '400px': 400px,
    '420px': 420px,
    '512px': 512px,
    '560px': 560px,
    '600px': 600px,
    '640px': 640px,
    '700px': 700px,
    '750px': 750px,
    '768px': 768px,
    '850px': 850px,
    '880px': 880px,
    '910px': 910px,
    '920px': 920px,
    '930px': 930px,
    '960px': 960px,
    '1024px': 1024px,
    '1200px': 1200px,
    '1440px': 1440px,
    '1600px': 1600px,
    '1920px': 1920px
  );

  @each $name, $value in $widths {
    .#{$prefix}\:#{$name} { width: $value; }
    .#{$prefix}\:m#{$name} { max-width: $value; }
  }

  @each $name, $value in $heights {
    .#{$prefix}\:#{$name} { height: $value; }
  }

  // Generate pixel-based width and height classes
  @each $size-name, $size-value in $pixel-sizes {
    .#{$prefix}\:w-#{$size-name} { width: $size-value; }
    .#{$prefix}\:mw-#{$size-name} { max-width: $size-value; }
    .#{$prefix}\:h-#{$size-name} { height: $size-value; }
  }
}
