@use 'mat';
@import 'datetimepicker/datetimepicker-theme';
@import 'normalize.css/normalize';
@import 'nouislider/dist/nouislider.css';
@import './variables';

/** material and base theme from zef lib */
@include mat.core();
@import 'theme';
/** @include mat.all-component-themes($appTheme); */
@include mat.all-legacy-component-themes($appTheme);
@include mat-datetimepicker-theme($appTheme);

/** base local */
@import './base/page';
@import './base/typography';
@import './base/dark-theme';

/** base from zef lib **/
@import 'base/material';
@import 'base/zef';

/** base components from zef lib **/
@import 'components/c-inline-list';
@import 'components/c-button';
@import 'components/c-dialog';
@import 'components/c-internal-link';
@import 'components/c-info-card';

/** base utils from zef lib **/
@import 'util/text';
@import 'util/color';
@import 'util/background';
@import 'util/position';
@import 'util/global';

/* CUF */
@import 'cuf/grid';
@import 'cuf/spacing';
@import 'cuf/typography';
@import 'cuf/positioning';
@import 'cuf/overflow';
@import 'cuf/vertical-align';
@import 'cuf/sizes';
@import 'cuf/display';

@include generate-grid-utilities();
@include generate-spacing-utilities();
@include generate-typography-utilities();
@include generate-positioning-utilities();
@include generate-overflow-utilities();
@include generate-vertical-align-utilities();
@include generate-sizes-utilities();
@include generate-display-utilities();
