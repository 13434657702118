@mixin generate-typography-utilities($prefix: "fx") {
  $font-sizes: (xs: 0.75rem, sm: 0.875rem, base: 1rem, lg: 1.125rem, xl: 1.25rem, '2xl': 1.5rem);
  $font-weights: (thin: 100, normal: 400, medium: 500, bold: 700);

  @each $name, $size in $font-sizes {
    .#{$prefix}\:text-#{$name} {
      font-size: $size;
    }
  }

  @each $weight-name, $weight in $font-weights {
    .#{$prefix}\:font-#{$weight-name} {
      font-weight: $weight;
    }
  }

  .#{$prefix}\:text-left { text-align: left; }
  .#{$prefix}\:text-center { text-align: center; }
  .#{$prefix}\:text-right { text-align: right; }
  .#{$prefix}\:text-justify { text-align: justify; }
}
