@use 'sass:math';
@import 'toolkit';

h1, h2, h3, h4, .t-h1, .t-h2, .t-h3, .t-h4, h5, .t-h5 {
  margin: 0;
  padding: 0;
}

h1, .t-h1 {

}

h2, .t-h3 {

}

h3, .t-h3 {
  font-weight: 500;
  line-height: 1.8em;
}

h4, .t-h4 {
  font-weight: 500;
  font-size: 16px;
}

h5, .t-h5 {

}

p {
  margin-top: 0;
  margin-bottom: math.div($bu, 2);
}

a {

}

code {
  font-family: $fontMono;
}

.u-desc {
  font-size: 13px;
  line-height: 1.75em;

  opacity: 0.7;
}
