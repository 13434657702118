/*
stacking
*/
$zIndexes: (
  header: 150,
  appBar: 200,
  menuPanel: 100,
);

$leftColumnWidth: 480px;
