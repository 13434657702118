@mixin generate-overflow-utilities($prefix: "fx") {
  .#{$prefix}\:overflow-hidden { overflow: hidden; }
  .#{$prefix}\:overflow-scroll { overflow: scroll; }
  .#{$prefix}\:overflow-auto { overflow: auto; }
  .#{$prefix}\:overflow-x-hidden { overflow-x: hidden; }
  .#{$prefix}\:overflow-y-hidden { overflow-y: hidden; }
  .#{$prefix}\:overflow-x-scroll { overflow-x: scroll; }
  .#{$prefix}\:overflow-y-scroll { overflow-y: scroll; }
  .#{$prefix}\:overflow-x-auto { overflow-x: auto; }
  .#{$prefix}\:overflow-y-auto { overflow-y: auto; }
}
