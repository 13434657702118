
// dark theme
html.zef-dark-theme {
    .c-z-notifications-pop-card {
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
      background: #1f2022 !important;

      .__group-header-title {
        background: #fff8e212;
      }

      .c-process-notification-shell-content {
        &.is-base {
          background: #1b1c1d;
        }

        &.is-red {
          background: #4a1f1f !important;
        }
      }
    }

    .mat-card {
      color: color(textWhite);
    }

    mat-card.mat-card {
      &.c-neu-card {
        background: #1a1b1c !important;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2), -3px -3px 12px rgba(40, 42, 43, 0.2);
        border: 1px solid rgba(34, 35, 36, 0.1);
      }

      &.c-soft-elevation {
        background-color: #202020;
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2), -3px -3px 12px rgba(40, 42, 43, 0.2);
      }

      &.c-form-card {
        background: #1b1c1d;
      }
    }

    .mat-stroked-button {
      border-color: rgb(255, 255, 255, 0.12);
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      border-radius: 5px;
      color: rgb(61 61 61 / 87%);
    }

    .mat-input-element {
      &::placeholder {
        color: rgb(61 61 61 / 87%);
      }
    }

    .cdk-overlay-dark-backdrop {
      background: rgba(52, 52, 52, 0.79);
    }

    .mat-dialog-container {
      background: #1f2022;
      box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2), -3px -3px 12px rgba(40, 42, 43, 0.2);
    }

    .mat-form-field-outline {
      background: #afafaf !important;
    }

    .mat-select-panel {
      background: #afafaf;
    }

    .mat-autocomplete-panel {
      background: #afafaf;
    }

    .mat-list-item-disabled {
      background: none  ;
    }

    .mat-input-element {
      color: #171819;
    }

    .mat-hint {
      color: #424343
    }
  }
