@mixin generate-display-utilities($prefix: "fx") {
  $displays: (
    "block": block,
    "inline": inline,
    "inline-block": inline-block,
    "table": table,
    "inline-table": inline-table,
    "none": none
  );

  @each $name, $value in $displays {
    .#{$prefix}\:display-#{$name} {
      display: $value;
    }
  }
}
