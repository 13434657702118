@use '@angular/material' as mat;
@use 'sass:map';

$mat-datetimepicker-selected-today-box-shadow-width: 1px;

@mixin mat-datetimepicker-theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, accent);

  .mat-datetimepicker-calendar-header {
    background-color: mat.get-color-from-palette($primary);
  }

  .mat-datetimepicker-calendar-body-cell-content {
    &.mat-datetimepicker-calendar-body-selected {
      background-color: mat.get-color-from-palette($primary);
      color: white;
    }

    &.mat-datetimepicker-calendar-body-disabled
      > .mat-datetimepicker-calendar-body-selected {
      background-color: rgba(mat.get-color-from-palette($primary), 0.4);
    }

    &.mat-datetimepicker-calendar-body-today {
      &:not(.mat-datetimepicker-calendar-body-selected) {
        border-color: mat.get-color-from-palette($primary);
      }

      &.mat-datetimepicker-calendar-body-selected {
        box-shadow: inset 0 0 0
          $mat-datetimepicker-selected-today-box-shadow-width
          mat.get-color-from-palette($primary, default-contrast);
      }
    }
  }

  .mat-datetimepicker-clock-center {
    background-color: mat.get-color-from-palette($primary);
  }

  .mat-datetimepicker-clock-hand {
    background-color: mat.get-color-from-palette($primary);

    &::before {
      background-color: mat.get-color-from-palette($primary);
    }
  }

  .mat-datetimepicker-clock-cell {
    &.mat-datetimepicker-clock-cell-selected {
      background-color: lighten(mat.get-color-from-palette($primary), 5);
    }
  }
}
