@mixin generate-vertical-align-utilities($prefix: 'fx') {
  $verticalAligns: (
    'baseline': baseline,
    'sub': sub,
    'super': super,
    'text-top': text-top,
    'text-bottom': text-bottom,
    'middle': middle,
    'top': top,
    'bottom': bottom
  );

  @each $name, $value in $verticalAligns {
    .#{$prefix}\:align-#{$name} { vertical-align: $value; }
  }
}
