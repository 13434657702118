@import 'zef-toolkit';

.mat-button,
.mat-stroked-button,
.mat-raised-button,
.mat-flat-button {
  border-radius: 17px !important;
  padding: 0 $bu !important;
  line-height: 38px !important;
}

.mat-card {
  border-radius: 10px !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.79);
}

.cdk-overlay-darker-backdrop {
  background: rgba(255, 255, 255, 0.95);
}

.cdk-overlay-light-dark-backdrop {
  background: rgba(0, 0, 0, 0.25);
}

.c-dialog-no-padding .mat-dialog-container {
  padding: 0 !important;
}

.mat-dialog-container {
  border-radius: 16px !important;
  box-shadow: 0px 11px 15px -7px rgba(131, 131, 131, 0.2), 0px 24px 38px 3px rgba(133, 133, 133, 0.14), 0px 9px 46px 8px rgba(134, 134, 134, 0.12);
  overflow: initial !important;
}

.mat-form-field {
  display: block;
  width: 100%;
}

.mat-form-field-hint-wrapper {
  line-height: 1.6em;
}

.c-mat-select-long-panel {
  max-height: 500px !important;
}

@keyframes animateGradient {
  0% { background-position: 0% 0%, 0% 0%, 0% 0%; }
  50% { background-position: 50% 50%, 50% 50%, 50% 50%; }
  100% { background-position: 100% 100%, 100% 100%, 100% 100%; }
}

mat-card.mat-card {
  border-radius: 4px;

  &:not([class*=mat-elevation-z]):not([class*=z-manual-elevation]):not([class*=c-neu-card]):not([class*=c-light-card]):not([class*=c-soft-elevation]) {
    box-shadow: none;
    transition: border-color 600ms;
  }


  &.c-neu-card {
    // box-shadow: -8px -8px 18px 0 rgba(#f8f9fb, 0.6), 8px 8px 18px 0 rgba(207, 210, 216, 0.37);
    // background: color(background);
    // border: 2px solid rgba(204, 204, 204, 0.05);
    // background: linear-gradient(rgba(236, 239, 243, 0.715), rgba(236, 239, 243, 0.823)) padding-box, radial-gradient(circle at top right, rgba(0, 204, 187, 0.6), hsla(180, 100%, 50%, 0.06)) border-box, radial-gradient(circle at bottom left, rgb(204 0 119 / 18%), hsla(328, 100%, 54%, 0.19)) border-box;
    background: #f3f5f7;
    border: 0px solid #ebeff387;
    box-shadow: none;
    border-radius: 12px;
  }

  &.c-light-card {
    background: #f3f5f7;
    box-shadow: none;
  }

  &.c-form-card {
    padding: $bu * 2;

    background: #f2f5f7;
  }

  &.c-soft-elevation {
    box-shadow: 0px 11px 15px -7px rgb(131 131 131 / 20%), 0px 24px 38px 3px rgb(133 133 133 / 14%), 0px 9px 46px 8px rgb(134 134 134 / 12%);
  }

  &.c-info-card {
    border: 4px solid #e8e8e8;

    box-shadow: 0px 11px 15px -7px rgb(131 131 131 / 6%), 0px 24px 38px 3px rgb(133 133 133 / 1%), 0px 9px 46px 8px rgb(134 134 134 / 1%);
  }

  &.has-no-padding {
    padding: 0;
  }

}

.mat-list-base.mat-list-base {
  margin-top: 0;
  padding-top: 0;
}

.mat-list-item[disabled], .mat-list-item-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.mat-list-base[dense] .mat-list-item {
  height: 38px !important;
  line-height: 38px !important;
}

zef-form, .zef-form {

  form zef-form-field {
    .mat-form-field-should-float {
      input.mat-input-element {
        height: 2em;
      }

      textarea.mat-input-element {
        margin-top: 3px;
      }
    }

    &.is-submitted {
      mat-form-field {
        &.ng-invalid:not(.mat-focused) {
          .mat-form-field-outline.mat-form-field-outline-thick {
            color: color(identityRed);
            opacity: 1;
          }

          .mat-form-field-invalid .mat-input-element {
            caret-color: color(identityRed);
          }

          .mat-form-field-label {
            color: color(identityRed)
          }
        }
      }
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-outline {
        background: white;
        border-radius: 6px;
      }

      .mat-form-field-infix {
        padding: 1.5em 0 1em 0;
      }

      .input.mat-input-element {
        margin-top: -0.0625em;
      }

    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      transform: translateY(-0.9em) scale(0.75);
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: inherit;
    }

    .mat-form-field-appearance-outline .mat-form-field-label {
      top: 1.84375em;
      margin-top: 0.075em;
    }

  }

  .mat-form-field-prefix {
    top: -6px !important;
    margin-right: 3px;
  }

}

.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
  left: 0;
}

.c-dark-bg-select {
  background: #2d2d2d;
  box-shadow: 0px 2px 4px -1px rgba(169, 169, 169, 0.2), 0px 4px 5px 0px rgba(169, 169, 169, 0.14), 0px 1px 10px 0px rgba(169, 169, 169, 0.12);

  .mat-option {
    color: #f1f1f1 !important;
  }

  .mat-option.mat-selected:not(.mat-option-multiple) {
    color: #0077CC !important;
    font-weight: 500;
  }
}

.mat-datetimepicker-calendar-header {
  // background: #0077CC;
  min-width: 210px !important;
  width: 210px !important;
  color: #fff !important;
}

// .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-today:not(.mat-datetimepicker-calendar-body-selected) {
//   border-color: #0077CC !important;
// }

// .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
//   background: #0077CC !important;
// }

// .mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell-selected {
//   background: #0077CC !important;
// }

// .mat-datetimepicker-clock-hand {
//   background: #0077CC !important;
// }

// .mat-datetimepicker-clock-center {
//   background: #0077CC !important;
// }

// .mat-datetimepicker-clock-hand::before {
//   background: #0077CC !important;
// }
